<template>
  <div>
    <div class='tts-wrap' v-show='showtts'>
      <div class='start-btn'>
        <span v-if='!startflag' @click='btts' class='iconfont ico'>&#xe6a4;</span>
        <span v-else-if='!composeSuccessed && startflag' @click='stopbtts'>
          <img class='lod'
               src='http://cdn.9kd.com/kdnet/upload_img_loading.gif?id=is_loading_img' />
        </span>
        <span v-else @click='stopbtts' class='iconfont ico'>&#xe6a2;</span>
      </div>
      <div class='change-btn'>
        <span class='langue'>{{ ttsRecorder.voiceName | selectVoice }}</span>
        <span class='change' @click='showSheet = true'><i class='iconfont ico2'>&#xe6a5;</i></span>
      </div>
    </div>
    
    <van-action-sheet v-model='showSheet' title='声音切换'>
      <div class='sheet-content' v-for='(item,index) in actions' :key='item.name' @click='onSelect(item,index)'>
        <span class='voice-name ' :class="{'active':ttsRecorder.voiceName===item.language}">{{ item.name }}</span>
        <span v-show='ttsRecorder.voiceName===item.language' class='iconfont ico'>&#xe6a6;</span>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  props: {
    ttsRecorder:{
      type: Object,
      default: () => {}
    },
    startflag:{
      type: Boolean,
      default: false
    },
    composeSuccessed:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showSheet: false,
      actions: [
        { language: 'xiaoyu', name: '普通话(男声)' },
        { language: 'xiaoyan', name: '普通话(女声)' },
        { language: 'vinn', name: '普通话(童声)' },
        { language: 'vils', name: '普通话(老年)' },
        { language: 'vixm', name: '粤语' },
        { language: 'vixl', name: '台湾话' },
        { language: 'vixr', name: '四川话' },
        { language: 'vixyun', name: '东北话' },
        { language: 'vixk', name: '河南话' },
        { language: 'vixqa', name: '湖南话' },
        { language: 'vixying', name: '陕西话' }
      ],
      showtts: true
    }
  },
  filters: {
    selectVoice(val) {
      if (val === 'xiaoyu') return '普通话(男声)'
      if (val === 'xiaoyan') return '普通话(女声)'
      if (val === 'vinn') return '普通话(童声)'
      if (val === 'vils') return '普通话(老年)'
      if (val === 'vixm') return '粤语'
      if (val === 'vixl') return '台湾话'
      if (val === 'vixr') return '四川话'
      if (val === 'vixyun') return '东北话'
      if (val === 'vixk') return '河南话'
      if (val === 'vixqa') return '湖南话'
      if (val === 'vixying') return '陕西话'
    }
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll(), true);
  },
  methods: {
    btts() {
      this.$emit('btts')
    },
    stopbtts() {
      this.$emit('stop-btts')
    },
    onSelect(item,index) {
      this.showSheet = false;
      this.$emit('select-voice', item, index)
    },
    // 滚动函数
    handleScroll() {
      var self = this;
      let timer;
      return function () {
        self.showtts = false;
        if(timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          self.showtts = true;
        }, 500);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.tts-wrap {
  // max-width: 192px;
  height: 47px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.10);
  position: fixed;
  bottom: 90px;
  left: 0;
  border-radius: 0 60px 60px 0;
  display: flex;
  align-items: center;
  z-index: 101;

  .start-btn {
    margin-left: 16px;
    margin-right: 12px;

    .lod {
      width: 20px;
      height: 20px;
    }

    .ico {
      line-height: 50px;
      font-size: 16px;
      color: #212121;
    }
  }

  .change-btn {
    font-size: 14px;

    margin-right: 15px;

    .change {
      color: #b2b2b2;
      margin-left: 10px;

      .ico2 {
        font-size: 12px;
      }
    }

    .langue {
      font-size: 15px;
      color: #24212c;
    }
  }
}
.sheet-content {
  width: 100%;
  height: 50px;
  opacity: 1;
  background: #ffffff;
  box-sizing: border-box;
  padding: 0 27px 0 20px;
  line-height: 50px;
  font-size: 15px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;

  .voice-name {
    &.active {
      color: #F7321C;
    }
  }

  .ico {
    font-size: 12px;
    color: #F7321C;
  }
}
</style>